<template>
  <div class="cardContain flex-center">
    <div>
<!--      <img src="../../../assets/images/xmzs.png" alt="" srcset="">-->
      <img :src="parentmsg.url" class="img" alt="" srcset="">
    </div>
    <div class="top-right">
      <div class="title">{{parentmsg.name}}</div>
      <div class="num" :style="{color:parentmsg.color||'#000000'}">{{parentmsg.num}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props:{
    parentmsg:{
      type:Object,
      default:()=>{}
    }
  }
}
</script>

<style lang="stylus" scoped>
.cardContain{
  border-width: 0px;
  width: 236.6px;
  height: 132.6px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  .img{
    width: 50px;
    height: 50px;
    margin-right 15px
  }
  .top-right{
    .title{
      font-size:16px
    }
    .num{
      text-align:left;
      font-family: '黑体', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 28px;
    }
  }
}
</style>
