import fetch from '@/apis/request'

export default class Apis {
    // 查询顶部数据
    static getTopData(formData) {
        return fetch.get('/pc/hangingBasket/homePageCount', {
            params:formData
        })
    }
    // 当天吊篮巡检情况
    static getTodayBasketData(formData) {
        return fetch.get('/pc/hangingBasket/checkCountByDay', {
            params:formData
        })
    }
    // 应巡检
    static getCheckPageByDay(formData) {
        return fetch.get('/pc/hangingBasket/checkPageByDay', {
            params:formData
        })
    }
    // 已巡检
    static getCheckingCountByDay(formData) {
        return fetch.get('/pc/hangingBasket/checkingCountByDay', {
            params:formData
        })
    }
    // 未巡检
    static getNotCheckCountByDay(formData) {
        return fetch.get('/pc/hangingBasket/notCheckCountByDay', {
            params:formData
        })
    }
    // 出现隐患检查部位占比
    static getProblemPercent(formData) {
        return fetch.get('/pc/hangingBasket/partChart', {
            params:formData
        })
    }
    // 前八项问题排行
    static getProblemChart(formData) {
        return fetch.get('/pc/hangingBasket/problemChart', {
            params:formData
        })
    }
}
