<template>
  <div class="home">
    <el-row :gutter="20" v-if="departmentType==='project'">
      <el-col :span="4" v-for="(item,index) in cardListTwo" :key="index">
        <card :parentmsg="item"></card>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-else>
      <el-col :span="4" v-for="(item,index) in cardList" :key="index">
        <card :parentmsg="item"></card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="dataContain flex leftDataContain">
          <div class="leftData  flex-column">
            <div class="title">当天吊篮巡检情况</div>
            <div class="flex-column titleRows">
              <div class="num" @click="showList(1)">{{ todayData.checkCount }}</div>
              <div class="tab">应巡检数量</div>
            </div>
            <div class="flex-column titleRows">
              <div class="num" @click="showList(2)">{{ todayData.checkingCount }}</div>
              <div class="tab">已巡检数量</div>
            </div>
            <div class="flex-column titleRows">
              <div class="num" @click="showList(3)">{{ todayData.notCheckCount }}</div>
              <div class="tab">未巡检数量</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div id="main" class="dataContain pie flex-center"></div>
      </el-col>
      <el-col :span="8">
        <div class="dataContain flex leftDataContain">
          <div class="leftData  flex-column">
            <div class="title">前八项问题排行</div>
            <div v-for="(item,index) in checkList" :key="index" class="problemItem">
              <div>{{ item.name }}</div>
              <el-progress :percentage="item.value/5" :format="format"></el-progress>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--  应巡检、已巡检、未巡检列表  -->
    <el-dialog
        ref="chooseRefs"
        title="详情"
        v-if="isShowList"
        :visible.sync="isShowList"
        @close="close"
        width="60%">
      <div class="content flex-column">
        <div class="cont">
          <el-table
              :data="XunJianList"
              :header-cell-style="{background:'#f5f5f5',color:'#333333'}"
          >
            <el-table-column align="center" label="吊篮位置">
              <template slot-scope="{ row }">
                <div type="primary">{{ row.location }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="departmentName"  label="编制机构" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
          <div class="flex-center" style="padding:10px 0;">
            <el-pagination
                background
                :current-page.sync="searchForm.pageCurrent"
                :page-size="searchForm.pageSize"
                :total="XunJianTotal"
                layout="total, prev, pager, next"
                @current-change="handleSizeChangeData"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import card from './components/card'
import * as echarts from 'echarts';
import {mapGetters} from "vuex";
import apis from "@/views/menus/api/home";

  export default {

    name: 'home',

    components: {
      card,
    },

    props: {
    },

    data() {
      return {
        activeName: 'first',
        departmentType:'company',
        isShowList:false,
        todayData:{},
        XunJianList:[],
        optionItems:{},
        searchForm:{
          pageCurrent:1,
          pageSize:10,
        },
        departmentCode:'',
        cardList:[
          {
            name:'项目总数量',
            url:require('../../assets/images/xmzs.png'),
            num:0,
            key:'projectCount',
            color:'#02A7F0'
          },
          {
            name:'用户数量',
            url: require('../../assets/images/yhsl.png'),
            key:'userCount',
            num:0,
            color:'#BFBF00'
          },
          {
            name:'吊篮总数量',
            url:require('../../assets/images/dlsl.png'),
            num:0,
            key:'totalCount',
            color:'#04F21C'
          },
          {
            name:'停用数量',
            url:require('../../assets/images/tysl.png'),
            num:0,
            key:'stopCount',
            color:'#D9001B'
          },
          {
            name:'待整改数量',
            url:require('../../assets/images/dzgsl.png'),
            num:0,
            key:'repairCount',
            color:'#13227A'
          },
          {
            name:'待复查数量',
            url:require('../../assets/images/dfcsl.png'),
            num:0,
            key:'reviewCount',
            color:'#D4237A'
          },
        ],
        cardListTwo:[
          {
            name:'吊篮总数量',
            url:require('../../assets/images/dlsl.png'),
            num:0,
            key:'totalCount',
            color:'#04F21C'
          },
          {
            name:'已停用数量',
            url:require('../../assets/images/tysl.png'),
            num:0,
            key:'stopCount',
            color:'#D9001B'
          },
          {
            name:'待整改数量',
            url:require('../../assets/images/dzgsl.png'),
            num:0,
            key:'repairCount',
            color:'#13227A'
          },
          {
            name:'超期未整改数量',
            url:require('../../assets/images/xmzs.png'),
            num:0,
            key:'timeoutNoRepairCount',
            color:'#02A7F0'
          },
          {
            name:'待复查数量',
            url:require('../../assets/images/dfcsl.png'),
            num:0,
            key:'reviewCount',
            color:'#D4237A'
          },
          {
            name:'已拆除数量',
            url: require('../../assets/images/yhsl.png'),
            num:0,
            key:'removeCount',
            color:'#BFBF00'
          },
        ],
        checkList:[],
        XunJianTotal:0,
        XunJianType:1,
      }
    },

    computed: {
      ...mapGetters([
        'companyId',
        'optionItem',
        'userInfo'
      ]),
    },

    watch: {
      optionItem:function value(val){
        this.optionItems = val
        this.departmentType = this.optionItems.departmentType
        this.departmentCode = this.optionItems.departmentCode
        this.init()
        this.initTopData()
        this.initBasketData()
        this.initProblemData()
      }
    },
    mounted() {
      this.optionItems = this.optionItem
      try {
        this.departmentType = this.optionItem.departmentType
        this.departmentCode = this.optionItem.departmentCode
        this.init()
        this.initTopData()
        this.initBasketData()
        this.initProblemData()
      }catch (e){
        console.log(e)
      }
    },

    methods: {
      init() {
        apis.getProblemPercent({
          departmentCode:this.departmentCode
        }).then((res)=>{
          console.log(res,'res')
          let chartDom = document.getElementById('main');
          let myChart = echarts.init(chartDom);
          let option = {
            title: {
              text: '出现隐患检查部位占比',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [{
              type: 'pie',
              data:res
            }]
          };
          option && myChart.setOption(option);
        })
      },
      initTopData(){
        apis.getTopData({
          departmentType:this.departmentType,
          departmentCode:this.departmentCode,
        }).then(res=>{
          if (this.departmentType == 'project'){
            this.$nextTick(()=>{
              this.cardListTwo[0].num = res.totalCount
              this.cardListTwo[1].num = res.stopCount
              this.cardListTwo[2].num = res.repairCount
              this.cardListTwo[3].num = res.timeoutNoRepairCount
              this.cardListTwo[4].num = res.reviewCount
              this.cardListTwo[5].num = res.removeCount
            })
          }else{
            this.$nextTick(()=>{
              this.cardList[0].num = res.projectCount
              this.cardList[1].num = res.userCount
              this.cardList[2].num = res.totalCount
              this.cardList[3].num = res.stopCount
              this.cardList[4].num = res.repairCount
              this.cardList[5].num = res.reviewCount
            })
          }
        })
      },
      initBasketData(){
        apis.getTodayBasketData({
          departmentCode:this.departmentCode
        }).then(res=>{
          this.todayData = res
        })
      },
      close(){
        this.isShowList = false
        this.XunJianList = []
        this.searchForm.pageCurrent = 1
      },
      showList(type){
        this.XunJianType = type
        this.searchForm.departmentCode=this.departmentCode
        switch (type) {
          case 1:
            apis.getCheckPageByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 2:
            apis.getCheckingCountByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 3:
            apis.getNotCheckCountByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
        }
        this.isShowList = true
      },
      async handleSizeChangeData(val) {
        // console.warn(val)
        this.searchForm.pageCurrent = val
        this.showList(this.XunJianType)
      },
      initProblemData(){
        apis.getProblemChart({
          departmentCode:this.departmentCode
        }).then(res=>{
          this.checkList = res
        })
      },
      format(percentage) {
        return percentage === 100 ? '满' : `${percentage*5}`;
      }
    }
  }
</script>

<style lang="stylus">
  // @import "../../assets/var"
   .home{
     height: 100%;
     .dataContain{
       margin-top:30px;
       width: 555px;
       height: 625.5px;
       background: inherit;
       background-color: rgba(255, 255, 255, 1);
       border-radius: 6px;
       -moz-box-shadow: none;
       -webkit-box-shadow: none;
       box-shadow: none;
       .leftData{
        width 500px
        margin-top 20px
        text-align left
        .title{
          font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 18px;
          margin-bottom 20px
        }
        .titleRows{
          border-top 1px dashed #8c939d
          margin-top 20px
          padding-top 20px
          margin-bottom 20px
          .num{
            cursor pointer
            font-family: '黑体', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            color: #000000;
            margin-bottom 20px
          }
          .num:hover{
            cursor pointer
            font-family: '黑体', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            color: #3396fb;
            margin-bottom 20px
          }
          .tab{
            font-size: 21px;
            color: #AAAAAA;
            font-family: 'Arial Normal', 'Arial', sans-serif;
            font-weight: 400;
            font-style: normal;
          }
        }
        .problemItem{
          margin 10px 0
          font-family: '微软雅黑', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          color: #AAAAAA;
        }
       }

     }
     .leftDataContain{
       justify-content center
     }
     .pie{
       padding:10px;
     }
   }
    // background-color $white
</style>
